<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            Discriminação
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="Os Atingidos e a discriminação em Mariana"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  No cotidiano marianense é comum ouvir pelas ruas e comércios
                  referências a uma suposta “boa vida dos atingidos”, que "antes
                  do rompimento da barragem viviam na miséria, hoje em dia vivem
                  comendo churrasco em restaurante” ou que vão morar em
                  “mansões” no reassentamento, “coisas que eles jamais sonhariam
                  se tivessem naqueles buracos onde eles viviam”. A associação
                  do rompimento à paralisação das atividades de mineração da
                  Samarco no município durante alguns anos responsabiliza as
                  vítimas do desastre-crime pela interrupção da atividade
                  econômica, que teria ocasionado à população urbana da cidade
                  estarem “ferrados, sem dinheiro e na miséria”<sup>1</sup>.
                </p>
                <p>
                  Logo após rumores da paralisação das atividades da Samarco,
                  ainda em 2015, o pleito "Fica Samarco" se intensificou. Parte
                  significativa dos políticos, comerciantes e moradores de
                  Mariana que defendiam esta bandeira procurava proteger seus
                  interesses no curto prazo, se prontificando a protestar contra
                  quaisquer medidas que poderiam prejudicar as operações da
                  Samarco no município com o slogan "Justiça sim, desemprego
                  não". Nesse contexto, Constrói-se uma narrativa que opõe os
                  direitos dos atingidos e atingidas aos interesses da cidade de
                  Mariana, em que implicitamente a concepção de justiça acionada
                  nos discursos comuns constrange a reparação à pressuposição
                  dos limites econômicos das empresas e, portanto, haveria que
                  se ser complacente com os responsáveis pela tragédia que
                  destruiu vidas, vivências, raízes, meios de trabalho,
                  estrutura econômica e de sociabilidade e etc.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_01.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row
        class="d-flex justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Quando saía para comprar alguma coisa, ou para acessar algum
            serviço, via as pessoas falando coisas ruins sobre as pessoas de
            Bento [...]. No início, as pessoas de Mariana se referiam aos
            atingidos como “mulambentos”, “os do barro”, “os da lama”.
          </p>
        </v-col>
      </v-row>

      <v-row
        class="d-flex flex-md-row-reverse justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="8"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Estamos super nervosos; temos filhos, escolas para pagar; estamos
            apreensivos. A empresa está errada, mas não é justo fechá-la”.
          </p>
          <p>
            “Durante a manifestação, foi distribuído um manifesto com a frase
            'agora, o momento é de recomeço; de reconstrução'!"<sup>2</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Adensadas pela ostensiva presença da Fundação Renova e pelas
                vultosas campanhas de publicidade em franco descompasso com as
                experiências das famílias atingidas, soma-se ao desgaste desses
                6 anos de vida provisória e perda de referências a
                deslegitimação das concepções, relatos e sofrimentos trazidos
                por aqueles que foram “engolidos pela lama”<sup>3</sup>. A
                desinformação faz com que a própria percepção de realidade
                atingidos e atingidas se transforme em território de disputa e
                que as interações mais corriqueiras venham a ser possíveis
                situações em que são diminuídos em suas angústias e que por
                vezes tentam desvencilhar-se do desconforto omitindo a condição
                de atingidos e não referindo-se à relação com as comunidades de
                origem.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex flex-column border__image3 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Acredito que o estresse e humilhações que venho passando desde o
            rompimento da barragem contribuiu para meu estado de saúde [...] e
            também por ver como os atingidos são discriminados pela população de
            Mariana [...]. Por ser atingida, me deparei e vivenciei algumas
            situações de preconceito e discriminação em Mariana [...]. Uma vez,
            fui a uma loja e perguntei o preço de uma blusa que tinha gostado, e
            a atendente não me deu atenção. Depois escutei uma pessoa da loja
            falando que não atenderia atingido [...]. Minha filha sofreu muita
            discriminação na escola por ser atingida, e eu como mãe sofro muito
            com o que minha filha passou. <strong>Atingida de 46 anos</strong>.”
          </p>
          <p style="color: #000 !important">
            “Essa frase me machucou muito. No meu trabalho no posto de saúde
            quando eu visito os atingidos é comum ouvir esses relatos de
            discriminação por que todos vêm passando. Eu já passei por muitos
            constrangimentos por conta de alguns moradores de Mariana.
            <strong>Atingida de 42 anos<sup>4</sup></strong
            >.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Em Mariana, os atingidos e atingidas tiverem que forçadamente se
                “adaptar” a uma nova realidade que não foi construída por eles,
                mas sim imposta, gerando diversos tipos de transtornos e
                instabilidades. Ademais, muitos desses atingidos e atingidas
                viviam em espaço inteiramente diferente da cidade, a falta de
                experiência específica com o trabalho apartado da terra e modo
                de vida rurais acarreta uma dificuldade ainda maior na busca por
                trabalho. Há diversos relatos de atingidos e atingidas que
                ficaram doentes devido à falta de trabalho e oportunidade. Não
                há iniciativas do poder público para realocá-las em novas
                ocupações que garantam o acesso a trabalho e ainda são taxados
                de aproveitadores, pois alegam que os atingidos e atingidas são
                beneficiados pela indenização paga pela Fundação Renova, sendo
                assim, estariam usurpando vagas de trabalhadores marianenses.
              </p>
              <p>
                Mesmo com a repercussão mundial do caso, até então nenhum
                responsável pelo desastre–crime foi responsabilizado, causando
                sensação de revolta, negligenciamento e impunidade. Passaram-se
                os anos, a empresa responsável pelo crime voltou à produção em
                Mariana, e os atingidos e atingidas que não tiverem seus
                direitos reparados ainda são vistos como "inimigos do
                desenvolvimento, folgados, preguiçosos" dentre diversos
                xingamentos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex flex-column border__image4 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Minha vida em Bento era muito corrida, sempre estava desempenhando
            alguma atividade. [...] Os moradores de Bento sofreram muito
            bullying, tem muitas pessoas de Mariana que falaram que os moradores
            de Bento estão se aproveitando da empresa Samarco.
            <strong>Atingido, 48 anos</strong>.”
          </p>
          <p>
            “Direto escuto de alguns falando que as pessoas de Bento estão
            ricas, pessoas ofendendo, chamando de folgados. Eu fico triste, mas
            não posso fazer nada. [...] Hoje não faço nada do que fazia antes,
            hoje é diferente. <strong> Atingida de 44 anos</strong>.”
          </p>
          <p>
            “Hoje em Mariana pra participar é difícil. Tive que sair da escola
            por conta do racismo. As pessoas não sabem o que passou e ficam
            xingando e zoando, falando que somos culpados pelo desemprego e por
            isso decidi não frequentar a escola. [...] Antes eu fazia planos de
            estudar e me formar, mas quando cheguei na escola de Mariana, as
            pessoas começaram a zombar, e eu desisti. [...] Com relação a
            emprego, é muito difícil. Pensou em abrir um negócio, mas com
            Mariana em crise, não tem como. Meu futuro vai ser uma enxada e uma
            roçadeira. <strong>Atingido, 20 anos<sup>5</sup>.</strong>”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Embora seja necessária ainda uma análise mais aprofundada, cabe
                ressaltar que as comunidades atingidas pela barragem de Fundão
                são comunidades de população majoritariamente negra. Ressalta-se
                aqui que embora os termos “estigma”, “discriminação” e “bullyng”
                sejam frequentes nos relatos, raramente os atingidos remetem ou
                correlacionam com a questão racial. No âmbito do trabalho da ATI
                da Cáritas em Mariana, foi possível observar desconsideração da
                autodeclaração racial para compor o questionário durante a
                reformulação do cadastro, a subdeclaração racial e a dificuldade
                de avanço da racialização da discussão.
              </p>
              <p style="text-align: center">
                <strong>Autora: Elenice da Cruz Calisto<sup>6</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> As frases entre aspas nesse primeiro parágrafo foram
              ouvidas pelos assessores da Cáritas durante o almoço em um
              restaurante, tendo sido proferidas na conversa de um grupo de
              empresários da mesa ao lado. Não obstante, foram aqui inseridas
              para exemplificar comentários recorrentes na cidade de Mariana que
              associam os atingidos a aproveitadores e imputam a eles a
              responsabilidade da suspensão temporária das atividades da Samarco
              após o rompimento.
            </p>
            <p>
              <sup>2</sup> HENRIQUE, João do Vale; LOPES, Valquiria. Justiça
              sim, desemprego não. Estado de Minas. 17/11/2015. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://www.em.com.br/app/noticia/gerais/2015/11/17/interna_gerais,709073/protesto-grupo-de-moradores-de-mariana-defende-mineradora.shtml"
                class="pink--text"
                >https://www.em.com.br/app/noticia/gerais/2015/11/17/interna_gerais,709073/protesto-grupo-de-moradores-de-mariana-defende-mineradora.shtml</a
              >.
            </p>
            <p>
              <sup>3</sup> Expressão utilizada por uma liderança durante fala em
              reunião ordinária da CABF.
            </p>
            <p>
              <sup>4</sup> Trechos extraídos dos depoimentos realizados durante
              a Tomada de Termo do Cadastro.
            </p>
            <p>
              <sup>5</sup> Trechos extraídos dos depoimentos realizados durante
              a Tomada de Termo do Cadastro.
            </p>
            <p>
              <sup>6</sup> Graduada em História pela Universidade Federal de
              Ouro Preto
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>
            <p>
              HENRIQUE, João do Vale; LOPES, Valquiria. Justiça sim, desemprego
              não. <strong>Estado de Minas</strong>. 17/11/2015. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://www.em.com.br/app/noticia/gerais/2015/11/17/interna_gerais,709073/protesto-grupo-de-moradores-de-mariana-defende-mineradora.shtml"
                class="pink--text"
                >https://www.em.com.br/app/noticia/gerais/2015/11/17/interna_gerais,709073/protesto-grupo-de-moradores-de-mariana-defende-mineradora.shtml</a
              >. Acesso em: 02 fev. 2022.
            </p>
            <p>
              FÁVERO, Giovanna. MPMG aponta ‘propaganda enganosa’ e pede fim da
              Fundação Renova. <strong>BHAZ</strong>. Belo Horizonte.
              25/02/2021. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://bhaz.com.br/noticias/minas-gerais/mpmg-aponta-propaganda-enganosa-e-pede-fim-da-fundacao-renova/#gref"
                class="pink--text"
                >https://bhaz.com.br/noticias/minas-gerais/mpmg-aponta-propaganda-enganosa-e-pede-fim-da-fundacao-renova/#gref</a
              >. Acesso em: 25 fev. 2022.
            </p>
            <p>
              FONTES, Roberta. Neves; LOPES, Ivonete. Silva. As representações
              da mídia sobre o rompimento da barragem do Fundão em Mariana,
              Minas Gerais. <strong>ESPACIOS</strong> (CARACAS) , v. 38, p. 32,
              2017.
            </p>
            <p>
              CÉSAR, Paulo Sérgio Mendes; CARNEIRO, Ricardo. O rompimento da
              barragem em Mariana para as populações historicamente vulneráveis.
              Revista livre de sustentabilidade e empreendedorismo , v. 2, p.
              223-240, 2017.
            </p>
            <p>
              MONTE, Naiara Baccarelli ; SILVA, Rudney da; VARGAS, Carla Regina;
              GUTIERRES Filho, Paulo José Barbosa; FERNANDES, Jorge Manuel Gomes
              de Azevedo. Ética, estigma e discriminação de grupos vulneráveis
              no processo educacional.
              <strong>Lecturas Educación Física y Deportes</strong> (Buenos
              Aires), v.14, nº 132, 2009.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_ser_atingido_estigma' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Estigma"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Discriminação",
          href: "conteudos_tematicos_ser_atingido_discriminacao",
        },
      ],
    };
  },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image4 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-4.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 0 2em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-1.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 4em;
  color: #000;
}

.border__image2 p:last-child {
  color: #000 !important;
  padding: 0 0 2em 8em;
  z-index: 20;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 4em 0 2em 8em;
  color: #000;
}

.border__image3 p:last-child {
  color: #000 !important;
  padding: 0 0 2em 8em;
  z-index: 20;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 4em 0 2em 8em;
  color: #000;
}

.border__image4 p:last-child {
  color: #000 !important;
  padding: 0 0 4em 8em;
  z-index: 20;
}

.border__image4 p:nth-child(2) {
  color: #000 !important;
  padding: 0 0 0 8em;
  z-index: 20;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
